.privacy-container {
background: #ffffff;
padding: 40px;
border-radius: 12px;
max-width: 800px;
width: 90%;
margin: 50px auto;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.privacy-title {
font-family: "Poppins", sans-serif;
font-weight: 600;
font-size: 24px;
color: #333;
text-align: center;
margin-bottom: 20px;
}

.privacy-effective-date {
font-family: "Poppins", sans-serif;
font-weight: 400;
font-size: 16px;
color: #555;
text-align: center;
margin-bottom: 20px;
}

.privacy-content {
font-family: "Poppins", sans-serif;
font-weight: 300;
font-size: 16px;
line-height: 1.6;
color: #333;
margin-bottom: 15px;
}

.privacy-subtitle {
font-family: "Poppins", sans-serif;
font-weight: 500;
font-size: 18px;
color: #000;
margin-top: 25px;
margin-bottom: 10px;
}

.privacy-list {
font-family: "Poppins", sans-serif;
font-weight: 300;
font-size: 16px;
color: #333;
padding-left: 20px;
margin-bottom: 15px;
}

.privacy-link {
color: #007bff;
text-decoration: none;
font-weight: 400;
}

.privacy-link:hover {
text-decoration: underline;
}

.privacy-contact {
font-family: "Poppins", sans-serif;
font-weight: 400;
font-size: 16px;
color: #333;
text-align: center;
line-height: 1.8;
margin-top: 10px;
}

.privacy-agreement {
font-family: "Poppins", sans-serif;
font-weight: 400;
font-size: 14px;
color: #555;
text-align: center;
margin-top: 20px;
  }
  