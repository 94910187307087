.terms-container {
    background: #ffffff;
    padding: 40px;
    border-radius: 12px;
    max-width: 800px;
    margin: 40px auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .terms-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
  }
  
  .terms-section-title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
    color: #333;
  }
  
  .terms-content {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6;
    color: #000;
    margin-bottom: 15px;
  }
  
  .terms-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-content a:hover {
    text-decoration: underline;
  }
  